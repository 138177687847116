
import { mutations } from '@/assets/scripts/store'

export default {
  created() {
    this.updatePopinContent()
  },

  mounted() {
    const popinData = mutations.getPopinData()
    if (popinData) {
      mutations.setDraft(popinData && popinData.draft)
    }
  },

  methods: {
    updatePopinContent() {
      if (this.$options.popinContentComponent) {
        mutations.setPopinComponent(this.$options.popinContentComponent)
      }
      if (this.$route.params.popin) {
        mutations.setPopinData(this.getPopinData())
        mutations.setPopinNavigationItems(this.getPopinNavItems())
      } else {
        mutations.setPopinData(null)
        mutations.setPopinNavigationItems(null)
      }
    },

    getPopinData() {
      return {}
    },

    getPopinNavItems() {
      return []
    },
  },
}
