
import { fetchFranchise } from '@/assets/scripts/api'
import DetailToPopin from '@/mixins/DetailToPopin'
import PageData from '@/mixins/PageData'

export default {
  name: 'FranchisePage',

  mixins: [PageData, DetailToPopin],

  async asyncData() {
    return { apiData: await fetchFranchise() }
  },
}
